import * as React from 'react';
import { PropertyInfo } from '@logiscool/scoolcode-language'
import { TypeLink } from "./TypeLink";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {OnlineInfoContent} from "./OnlineInfoContent";
import {ScoolcodeBlock} from "./ScoolcodeBlock";
import {DocsProvider} from "../../lib/docsProvider";

interface Props {
    type: PropertyInfo
}

export class PropertyCard extends React.Component<Props> {
    render() {
        const { type: property } = this.props;
        const kind = DocsProvider.extractKind(property.declaringType);
        const hasCode = !property.private;

        return (
            <Paper style={{ marginBottom: 20 }}>
                <Typography variant="title" component={(props: any) => 
                    <h3 {...props} id={property.name}>
                        {property.name}
                    </h3>} style={{ padding: 10 }}>
                </Typography>
                {hasCode && <>
                    <Divider />
                    <Typography style={{ fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace', padding: 10 }}>
                        {property.name}:&nbsp;
                        <TypeLink type={property.valueType} />
                        {' { '}
                        {property.getter ? 'get; ' : ''}
                        {property.setter ? 'set; ' : ''}
                        {'}'}
                    </Typography>
                </>}
                {(kind !== 'enum' && kind !== 'dynamic-enum') && <>
                    <Divider />
                    {property.getter && <ScoolcodeBlock type={property} />}
                    {property.setter && <ScoolcodeBlock type={property} modifier="set" />}
                    {property.setter && <ScoolcodeBlock type={property} modifier="change" />}
                </>}
                <Divider />
                <OnlineInfoContent
                    title={false}
                    category={property.declaringType.fullName}
                    target={property.fullName} />
            </Paper>
        )
    }
}