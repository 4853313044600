import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Auth from '../../lib/auth';

interface Props extends RouteComponentProps {

}

export class OauthCallbackPage extends Component<Props> {
    componentDidMount() {
        const {history} = this.props;
        try {
            const access_token = document.cookie
                .split(';')
                .map(v => v.split('='))
                .filter(([k]) => k.trim() === 'access_token')
                .map(([k, v]) => v)
                .reduce<string>((acc, token) => token || acc, '');
                Auth.saveToken(access_token);
                history.push(Auth.fetchPath());
        }
        catch(e: any) {
            Auth.saveToken('');
            console.log(e.message);
            history.push('/local-logout');
        }
    }

    render() {
        return null;
    }
}
