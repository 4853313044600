import * as React from 'react';
import { EventInfo } from '@logiscool/scoolcode-language'
import { TypeLink } from "./TypeLink";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {ScoolcodeBlock} from "./ScoolcodeBlock";

interface Props {
    type: EventInfo
}

export class MinimalEventCard extends React.Component<Props> {
    render() {
        const { type: event } = this.props;
        const hasCode = !event.private;

        return (
            <Paper>
                {hasCode &&<>
                    <Typography style={{ fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace', padding: 5 }}>
                        <strong>when</strong> {event.fullName}(
                        {event.parameters.map((param, i) => (
                            <span key={i}>
                                {param.name}: <TypeLink type={param.type} />
                                {i+1 < event.parameters.length ? ', ' : ''}
                            </span>
                        ))})
                    </Typography>
                    <Divider />
                </>}
                <ScoolcodeBlock type={event} />
            </Paper>
        )
    }
}