import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import yellow from '@material-ui/core/colors/yellow';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';

interface Props {
    author: string,
    content: string,
    status: number,
    canDelete?: boolean,
    onDelete?: () => void
    onStatusChange: (newStatus: number) => void
}

interface State {
    hover: boolean
}

const statusHash: Record<number, string> = {
    0: 'Open',
    1: 'TODO',
    2: 'In Progress',
    3: 'Testing',
    4: 'Done',
    5: 'Invalid',
    6: 'Postponed'
}

const statusStyle: any = {
    0: { },
    1: { background: amber[600], color: 'white' },
    2: { background: yellow[400], color: 'black' },
    3: { background: blue[600], color: 'white' },
    4: { background: green[600], color: 'white' },
    5: { background: red[200], color: 'black' },
    6: { background: blue[200], color: 'black' }
}

export class Comment extends React.Component<Props, State> {
    state = {
        hover: false
    }

    render() {
        const { hover } = this.state;
        const { author, content, status, canDelete, onDelete, onStatusChange } = this.props;

        const avatar = (canDelete && hover)
            ? <Avatar style={{ textTransform: 'uppercase', cursor: 'pointer' }} onClick={onDelete}><DeleteIcon/></Avatar>
            : <Avatar style={{ textTransform: 'uppercase' }}>{author[0]}</Avatar>

        return (
            <article onMouseEnter={() => this.setState({ hover: true })}
                     onMouseLeave={() => this.setState({ hover: false })}>
                <Divider/>
                <div style={{padding: '5px 5px'}}>
                    {author && <Chip avatar={avatar} label={author} style={{ textTransform: 'lowercase', marginRight: 3 }} />}
                    <Chip label={statusHash[status]} style={{ textTransform: 'lowercase', verticalAlign: 'top' , cursor: 'pointer', ...statusStyle[status] }}
                          onClick={() => onStatusChange(status >= 6 ? 0 : (status + 1))}/>
                    &nbsp;&nbsp;<Typography component="span" style={{ display: 'inline-block', marginTop: 5, verticalAlign: 'top' }}>{content}</Typography>
                </div>
            </article>
        )
    }
}
