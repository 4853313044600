import API from "../lib/api";
import {OnlineInfoEntry} from "../components/docsTypeInfo/OnlineInfoContent";

export const replaceEntry = (dispatch: any) => async (entry: OnlineInfoEntry) => {
    dispatch({
        type: 'REPLACE_ENTRY',
        payload: {
            entry
        }
    })
}

export const deleteEntry = (dispatch: any) => async (id: string) => {
    dispatch({
        type: 'DELETE_ENTRY',
        payload: {
            id
        }
    })
}

export const addEntry = (dispatch: any) => async (entry: OnlineInfoEntry) => {
    dispatch({
        type: 'ADD_ENTRY',
        payload: {
            entry
        }
    })
}

export const setCategory = (dispatch: any) => async (category: string) => {
    const onlineInfo = await API.fetchOnlineInfo(category);
    dispatch({
        type: 'SET_CATEGORY',
        payload: {
            category,
            onlineInfo
        }
    })
}

export const setPage = (dispatch: any) => async (page: number, filter: string|null, previousOnlineInfo: any = null) => {
    const { maxPage, log }: any = await API.fetchActivityLog(page, filter);

    if(!previousOnlineInfo) {
        previousOnlineInfo = [];
        for(let i = page-1; i >= 1; i--) {
            previousOnlineInfo = [
                ...(await API.fetchActivityLog(i, filter)).log,
                ...previousOnlineInfo
            ]
        }
    }

    const onlineInfo = page === 1
        ? log
        : [
            ...previousOnlineInfo,
            ...log
        ];

    dispatch({
        type: 'SET_PAGE',
        payload: {
            page,
            maxPage,
            onlineInfo
        }
    })
}

export const resetInfo = (dispatch: any) => () => {
    dispatch({
        type: 'RESET_INFO',
    })
}

export const setLanguage = (dispatch: any) => async (language: string) => {
    dispatch({
        type: 'SET_LANGUAGE',
        payload: {
            language
        }
    })
}
