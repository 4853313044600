import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import {OnlineInfoContent} from "../docsTypeInfo/OnlineInfoContent";

interface Props {
    category: string,
    target: string,
    expand?: boolean
}

export class InfoCard extends React.Component<Props> {
    render() {
        const { category, target, expand } = this.props;

        return (
            <>
                <Paper style={{ marginTop: 10, padding: 5 }}>
                    <OnlineInfoContent
                        title={true}
                        category={category}
                        target={target}
                        minimal={!expand}
                    />
                </Paper>
            </>
        )
    }
}