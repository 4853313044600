import * as React from 'react';
import { ClassInfo } from '@logiscool/scoolcode-language'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {DocsProvider} from "../../lib/docsProvider";

interface Props {
    type: ClassInfo
}

export class MinimalClassCard extends React.Component<Props> {
    render() {
        const { type } = this.props;
        return (
            <Paper style={{ padding: 5 }}>
                <Typography variant="title">{type.fullName}</Typography>
                <Typography variant="subheading">{DocsProvider.extractKind(type).replace('-',' ')}</Typography>
            </Paper>
        )
    }
}