import * as React from 'react';
import { MethodInfo } from '@logiscool/scoolcode-language'
import { TypeLink } from "./TypeLink";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {ScoolcodeBlock} from "./ScoolcodeBlock";

interface Props {
    type: MethodInfo
}

export class MinimalMethodCard extends React.Component<Props> {
    render() {
        const { type: method } = this.props;
        const hasBlock = method.blockType || method.blockDefinition;
        const hasCode = !method.private;

        return (
            <Paper>
                {hasCode && <Typography style={{ fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace', padding: 5 }}>
                    {method.fullName}(
                    {method.parameters.map((param, i) => (
                        <span key={i}>
                            {param.ref && <strong>ref&nbsp;</strong>}
                            {param.name}: <TypeLink type={param.valueType} />
                            {i+1 < method.parameters.length ? ', ' : ''}
                        </span>
                    ))}
                    ):&nbsp;<TypeLink type={method.returnType} />
                </Typography>}
                {(hasCode && hasBlock) && <Divider />}
                {hasBlock && <ScoolcodeBlock type={method} />}
            </Paper>
        )
    }
}