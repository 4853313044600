import * as React from 'react';
import { Component } from 'react';
import { Route, RouteProps, withRouter, RouteComponentProps } from 'react-router-dom';
import Auth from '../../lib/auth';
import axios from 'axios'
import API from '../../lib/api';
import LinearProgress from '@material-ui/core/LinearProgress'
import { runtimeEnv } from '../../env';

interface Props extends RouteProps {

}

class ProtectedRoute extends Component<Props & RouteComponentProps> {
    state = {
        ready: false
    }

    componentDidMount() {
        const {expiresOn = 0, token} = Auth.getToken();
        if (expiresOn < Date.now()) {
            Auth.logoutLocal();
            return
        }
        axios.get(`${runtimeEnv.REACT_APP_API_URL || 'https://api.scoolcode.com'}/accounts/me`, {params: {access_token: token}})
            .then(async ({data: user}) => {
                const locale = await API.fetchLocale()
                user.language = locale.language;
                user.grants = await API.checkGrants([
                    { product: 'edup.scoolcode.docs', feature: 'activity-log' },
                    { product: 'edup.scoolcode.docs', feature: 'editor-mode' },
                    { product: 'edup.scoolcode.ide', feature: 'language-stagescript' },
                    { product: 'edup.scoolcode.ide', feature: 'language-python' },
                ])
                Auth.saveUser(user);
                this.setState({ready: true})
            })
            .catch(e => {
                Auth.saveToken('');
                console.log(e.message);
                this.props.history.push('/local-logout');
            });

    }

    render() {
        const {component: OwnComp, render = () => null, ...rest} = this.props;
        const {expiresOn = 0} = Auth.getToken();
        if (!this.state.ready) {
            return <LinearProgress />
        }
        return (
            <Route
                {...rest}
                render={props => (expiresOn < Date.now())
                    ? null
                    : (!!OwnComp)
                        ? (<OwnComp {...props} />)
                        : render(props)}
            />
        );
    }
}

const enchanted = withRouter(ProtectedRoute)

export {
    enchanted as ProtectedRoute
}
