import * as React from 'react';
import { PropertyInfo, MethodInfo, EventInfo, Type, BlockGalleryFactory, BlockInstance, BlockContent } from '@logiscool/scoolcode-language'
import Chip from '@material-ui/core/Chip';
//import Avatar from '@material-ui/core/Avatar';
import { TypeLink } from "./TypeLink";
import Typography from '@material-ui/core/Typography';
import {createStyles, Theme, WithStyles, withStyles} from "@material-ui/core/styles";

const styles = (theme: Theme) => createStyles({
    place: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.disabled,
        marginLeft: 0,
        marginRight: 2,
        minWidth: 24,
        height: 24
    }
});


interface Props extends WithStyles<typeof styles> {
    type: MethodInfo|PropertyInfo|EventInfo|Type
    modifier?: string
}

class ScoolcodeBlock extends React.Component<Props> {
    renderContent = (content: BlockContent, i: number, all: any[]) => {
        const { classes } = this.props;

        switch(content.type) {
            case 'label':
                return <Typography key={i}>{content.text}{(i+1) < all.length ? (<>&nbsp;</>) : ''}</Typography>

            case 'place':
            case 'enum':
                return <React.Fragment key={i}><Chip className={classes.place} label={content.parameter.name} />{(i+1) < all.length ? (<>&nbsp;</>) : ''}</React.Fragment>

            default:
                return ''
        }
    }

    render() {
        const { type, modifier } = this.props;

        if(type instanceof PropertyInfo && type.blockRoot) {
            return (
                <Typography style={{ padding: 10 }}>This is a block root. Discover related blocks at <TypeLink type={type.valueType} />.</Typography>
            )
        }

        const block = BlockGalleryFactory.block(type.fullName + (modifier ? `.${modifier}` : '')).block(null);

        if(block instanceof BlockInstance) {
            const part = block.parts[0];
            return (
                <Chip style={{ margin: 5 }} label={
                    part.content.map(this.renderContent)
                }/>
            )
        }
        else {
            return (
                <Typography style={{ padding: 10 }}>Complex blocks are not yet supported in the documentation.</Typography>
            )
        }
    }
}

const ScoolcodeBlockWithStyle = withStyles(styles)(ScoolcodeBlock);
export { ScoolcodeBlockWithStyle as ScoolcodeBlock };