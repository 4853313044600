import { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Auth from '../../lib/auth';

interface Props extends RouteComponentProps<{}> {

}

export const LocalLogoutPage: FC<Props> = props => {
    useEffect(() => {
        Auth.logoutLocal();
    }, []);

    return null;
};
