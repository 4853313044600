import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {createStyles, Theme, withStyles, WithStyles} from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme: Theme) => createStyles({
    inputRoot: {
        flexWrap: 'wrap',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        border: 'none'
    },
    wrapper: {
        margin: theme.spacing.unit,
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

interface Props extends WithStyles<typeof styles> {
    open: boolean
    saving: boolean
    defaultContent?: string
    onClose: (content?: string|null) => void
}

interface State {
    content: string|null
}

class ArticleEditorDialog extends React.Component<Props, State> {
    state = {
        content: null
    }

    render() {
        const { content } = this.state;
        const { classes, defaultContent, open, saving, onClose } = this.props;

        return (
            <Dialog
                open={open || saving}
                onClose={() => onClose()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{defaultContent ? 'Edit' : 'Create'} Article</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the content of the article here. You can user markdown formatting, including
                        tables, images and source code.
                    </DialogContentText>
                    <TextField
                        classes={{
                            root: classes.inputRoot
                        }}
                        autoFocus
                        margin="dense"
                        id="name"
                        fullWidth
                        multiline
                        value={(typeof content === 'string' ? content : defaultContent) || ''}
                        disabled={saving}
                        onChange={e => this.setState({ content: e.target.value })}
                        inputProps={{
                            rows: 14,
                            rowsMax: 14
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}
                            variant="contained"
                            disabled={saving}>
                        Cancel
                    </Button>
                    <div className={classes.wrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={saving}
                            onClick={() => onClose(content)}
                        >
                            Save
                        </Button>
                        {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

const ArticleEditorDialogWithStyle = withStyles(styles)(ArticleEditorDialog);
export { ArticleEditorDialogWithStyle as ArticleEditorDialog }