import * as React from 'react';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import {DocsProvider, TypeIndexEntry} from "../../lib/docsProvider";
import {MinimalPropertyCard} from "../docsTypeInfo/MinimalPropertyCard";
import {MinimalMethodCard} from "../docsTypeInfo/MinimalMethodCard";
import {MinimalEventCard} from "../docsTypeInfo/MinimalEventCard";
import {MinimalClassCard} from "../docsTypeInfo/MinimalClassCard";
import {Link} from "react-router-dom";
import { connect } from 'react-redux';

DocsProvider.buildTypeIndex();

function renderInput(inputProps: any) {
    const { InputProps, classes, ref, ...other } = inputProps;
    return (
        <TextField
            InputProps={{
                inputRef: ref,
                disableUnderline: true,
                classes: {
                    root: classes.inputRoot,
                },
                startAdornment: (
                    <InputAdornment position="start" style={{ marginLeft: 10 }}>
                        <SearchIcon />
                    </InputAdornment>
                ),
                ...InputProps,
            }}
            {...other}
        />
    );
}

const kindToCard: Record<string, any> = {
    'property': MinimalPropertyCard,
    'method': MinimalMethodCard,
    'event': MinimalEventCard,
    'class': MinimalClassCard
};

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }: any) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;
    const Card = kindToCard[suggestion.kind];

    return (
        <MenuItem
            {...itemProps}
            key={suggestion.label}
            selected={isHighlighted}
            component={Link}
            to={suggestion.target}
            style={{
                fontWeight: isSelected ? 500 : 400,
                height: 'auto'
            }}
        >
            <Card type={suggestion.type} />
        </MenuItem>
    );
}

const styles = (theme: Theme) => createStyles({
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
        background: theme.palette.background.default
    },
    chip: {
        margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    },
    inputRoot: {
        flexWrap: 'wrap',
        backgroundColor: theme.palette.primary.light,
        border: 'none'
    },
});

interface Props extends WithStyles<typeof styles> {
}
interface State {
    currentLanguage: string    
}

class SearchBox extends React.Component<Props & StateProps, State> {
    state = {
        currentLanguage: ''
    }

    render() {
        const { classes } = this.props;

        if (this.props.language !== this.state.currentLanguage) {
            DocsProvider.buildTypeIndex();
            setTimeout(() => {
                this.setState({currentLanguage: this.props.language});
            }, 0);
        }

        return (
            <Downshift>
                {({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
                    <div className={classes.container}>
                        {renderInput({
                            fullWidth: true,
                            classes,
                            InputProps: getInputProps({
                                placeholder: 'Search the documentation...',
                                id: 'search-box',
                                value: isOpen ? (inputValue||'') : ''
                            }),
                        })}
                        {isOpen ? (
                            <Paper className={classes.paper} square>
                                {DocsProvider.getSuggestions(inputValue).map((suggestion: TypeIndexEntry, index: number) =>
                                    renderSuggestion({
                                        suggestion,
                                        index,
                                        itemProps: getItemProps({ item: suggestion.label }),
                                        highlightedIndex,
                                        selectedItem,
                                    }),
                                )}
                            </Paper>
                        ) : null}
                    </div>
                )}
            </Downshift>
        )
    }
}

interface StateProps {
    language: string
}

const enhanced = connect<StateProps>(
    (({ language }: any) => ({ language }))
)(SearchBox);

const SearchBoxWithStyle = withStyles(styles)(enhanced);
export { SearchBoxWithStyle as SearchBox }



