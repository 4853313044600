import * as React from 'react';
import { MethodInfo } from '@logiscool/scoolcode-language'
import { TypeLink } from "./TypeLink";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {OnlineInfoContent} from "./OnlineInfoContent";
import {ScoolcodeBlock} from "./ScoolcodeBlock";

interface Props {
    type: MethodInfo
}

export class MethodCard extends React.Component<Props> {
    render() {
        const { type: method } = this.props;
        const hasBlock = method.blockType || method.blockDefinition;
        const hasCode = !method.private;

        return (
            <Paper style={{ marginBottom: 20 }}>
                <Typography variant="title" component={(props: any) => 
                    <h3 {...props} id={method.name}>
                        {method.name}
                    </h3>} style={{ padding: 10 }}>
                </Typography>
                {hasCode && <>
                    <Divider />
                    <Typography style={{ fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace', padding: 10 }}>
                        {method.name}(
                        {method.parameters.map((param, i) => (
                            <span key={i}>
                                {param.ref && <strong>ref&nbsp;</strong>}
                                {param.name}: <TypeLink type={param.valueType} />
                                {i+1 < method.parameters.length ? ', ' : ''}
                            </span>
                        ))}
                        ):&nbsp;<TypeLink type={method.returnType} />
                    </Typography>
                </>}
                {hasBlock && <>
                    <Divider />
                    <ScoolcodeBlock type={method} />
                </>}
                <Divider />
                <OnlineInfoContent
                    title={false}
                    category={method.declaringType.fullName}
                    target={method.fullName} />
            </Paper>
        )
    }
}