import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import {DocsProvider} from "../../lib/docsProvider";
import {PropertyCard} from "./PropertyCard";
import {MethodCard} from "./MethodCard";
import {EventCard} from "./EventCard";
import {HierarchyCard} from "./HierarchyCard";
import {IndexCard} from "./IndexCard";
import {SummaryCard} from "./SummaryCard";
import { Redirect } from "react-router-dom"
import { connect } from 'react-redux';

interface Props {
    typeName: string
}

export class DocsTypeInfoPanel extends React.Component<Props & StateProps> {
    render() {
        const { typeName } = this.props;
        const typeInfo = DocsProvider.getType(typeName);

        if(!typeInfo) {
            if (this.props.language) {
                return <Redirect to={'/'} />
            }
            else {
                return null;
            }
        }

        return (
            <div style={{ paddingBottom: 30 }}>
                <Typography variant="display1">{typeInfo.fullName}</Typography>
                <Typography variant="subheading" gutterBottom>{DocsProvider.extractKind(typeInfo).replace('-',' ')}</Typography>
                <br/>
                <SummaryCard type={typeInfo} />
                <HierarchyCard type={typeInfo} />
                <br/>
                <br/>
                {(!!typeInfo.properties.length || !!typeInfo.methods.length || !!typeInfo.events.length) && <>
                    <Typography gutterBottom variant="headline">Index</Typography>
                    <IndexCard type={typeInfo} />
                    <br/>
                </>}
                {!!typeInfo.properties.length && <>
                    <Typography gutterBottom variant="headline">Properties</Typography>
                    {typeInfo.properties
                        .filter(prop => !prop.disabled && !prop.private)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(prop => <PropertyCard key={prop.fullName} type={prop} />)}
                    <br/>
                    <br/>
                </>}
                {!!typeInfo.methods.length && <>
                    <Typography gutterBottom variant="headline">Methods</Typography>
                    {typeInfo.methods
                        .filter(method => !method.disabled && !method.private)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(method => <MethodCard key={method.fullName} type={method} />)}
                    <br/>
                    <br/>
                </>}
                {!!typeInfo.events.length && <>
                    <Typography gutterBottom variant="headline">Events</Typography>
                    {typeInfo.events
                        .filter(event => !event.disabled && !event.private)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(event => <EventCard key={event.fullName} type={event} />)}
                </>}
            </div>
        )
    }
}

interface StateProps {
    language: string
}

const enhanced = connect<StateProps>(
    (({ language }: any) => ({ language }))
)(DocsTypeInfoPanel);

export { enhanced as DocsTypeInfo }
