import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {TypeLink} from "../docsTypeInfo/TypeLink";
import {DocsProvider} from "../../lib/docsProvider";

export class WelcomeCard extends React.Component {
    render() {
        return (
            <>
                <Typography variant="display1">Welcome to Scoolcode Docs!</Typography>
                <Typography variant="subheading" gutterBottom>It's time to get started...</Typography>
                <br/>

                <Paper style={{ marginBottom: 60 }}>
                    <Typography variant="title" component="h3" style={{ padding: 10 }}>Basics</Typography>
                    <Divider />
                    <div style={{ padding: 10, lineHeight: 1.6 }}>
                        <Typography component={'span'}>
                            <span>You should start with the two most important types:</span>
                            <ul>
                                <li><TypeLink type={DocsProvider.getType('ScoolcodeStage')}/></li>
                                <li><TypeLink type={DocsProvider.getType('ScoolcodeActor')}/></li>
                            </ul>
                            <span>Also you should check out:</span>
                            <ul>
                                <li><TypeLink type={DocsProvider.getType('ScoolcodePen')}/></li>
                                <li><TypeLink type={DocsProvider.getType('Physics')}/></li>
                            </ul>
                        </Typography>
                    </div>
                    <Typography variant="title" component="h3" style={{ padding: 10 }}>Read more</Typography>
                    <Divider />
                    <div style={{ padding: 10, lineHeight: 1.6 }}>
                        <Typography component={'span'}>
                            You can continue your journey in the Guide:
                            <ul>
                                <li><a href="https://bitbucket.org/logiscool/scoolcode-platform-ide/wiki/Differences%20between%20v1.5%20and%20vNext">Differences between v1.5 and vNext</a></li>
                                <li><a href="https://bitbucket.org/logiscool/scoolcode-platform-ide/wiki/Feedbacks%20and%20issue%20reporting">Feedback and Error reporting</a></li>
                            </ul>
                        </Typography>
                    </div>
                </Paper>
            </>
        )
    }
}