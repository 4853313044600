import * as React from 'react';
import { ClassInfo } from '@logiscool/scoolcode-language'
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

interface Props {
    type: ClassInfo
}

export class IndexCard extends React.Component<Props> {
    render() {
        const { type } = this.props;

        return (
            <Paper style={{ marginBottom: 10 }}>
                {!!type.properties.length && <>
                    <Typography variant="title" component="h3" style={{ padding: 10 }}>Properties</Typography>
                    <Divider />
                    <div className="link-list">
                        {type.properties
                            .filter(prop => !prop.disabled && !prop.private)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((p, i) => <span key={i}><a href={`#${p.name}`}>{p.name}</a></span>)}
                    </div>
                </>}
                {(!!type.properties.length && !!type.methods.length) && <br/>}
                {!!type.methods.length && <>
                    <Typography variant="title" component="h3" style={{ padding: 10 }}>Methods</Typography>
                    <Divider />
                    <div className="link-list">
                        {type.methods
                            .filter(method => !method.disabled && !method.private)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((p, i) => <span key={i}><a href={`#${p.name}`}>{p.name}</a></span>)}
                    </div>
                </>}
                {(!!type.methods.length && !!type.events.length) && <br/>}
                {!!type.events.length && <>
                    <Typography variant="title" component="h3" style={{ padding: 10 }}>Events</Typography>
                    <Divider />
                    <div className="link-list">
                        {type.events
                            .filter(event => !event.disabled && !event.private)
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((p, i) => <span key={i}><a href={`#${p.name}`}>{p.name}</a></span>)}
                    </div>
                </>}
            </Paper>
        )
    }
}