import * as React from 'react';
import { Type } from '@logiscool/scoolcode-language'
import { TypeLink } from "./TypeLink";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {DocsProvider} from "../../lib/docsProvider";

interface Props {
    type: Type
}

export class HierarchyCard extends React.Component<Props> {
    render() {
        const { type } = this.props;
        const hierarchy = DocsProvider.extractHierarchy(type);
        return (
            <Paper style={{ marginBottom: 10 }}>
                <Typography variant="title" component="h3" style={{ padding: 10 }}>
                    Hierarchy
                </Typography>
                <Divider />
                <Typography style={{ padding: 10 }} component="div">
                    <ul style={{ listStyleType: 'square' }}>
                        <li><TypeLink type={hierarchy.base} fallback='object' /></li>
                        <ul style={{ listStyleType: 'square', paddingLeft: 20 }}>
                            <li><strong>{hierarchy.type.fullName}</strong></li>
                            <ul style={{ listStyleType: 'square', paddingLeft: 20 }}>
                                {hierarchy.children.map(type => (
                                    <li key={type.fullName}><TypeLink type={type} /></li>
                                ))}
                            </ul>
                        </ul>
                    </ul>
                </Typography>
            </Paper>
        )
    }
}