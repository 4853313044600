import 'prismjs';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-diff';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-typescript';

const prism = require('prismjs');

prism.languages.stagescript = prism.languages.extend('javascript', {
    'keyword': /\b(?:actor|stage|backdrop|costume|parallax|sound|when|ref|let|this)\b/,
});

prism.languages.ss = prism.languages.stagescript;


const lightTheme = require('prismjs/themes/prism-coy.css');
const darkTheme = require('prismjs/themes/prism-okaidia.css');

export { lightTheme, darkTheme };

let styleNode: any;

/*if (process.browser) {
    styleNode = document.createElement('style');
    styleNode.setAttribute('data-prism', 'true');
    if (document.head) {
        document.head.appendChild(styleNode);
    }
}*/

export function setPrismTheme(theme: any) {
    styleNode.textContent = theme;
}

export default prism;