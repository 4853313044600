import * as React from 'react';
import Downshift from 'downshift';
import Paper from '@material-ui/core/Paper';
import CodeIcon from '@material-ui/icons/Code';
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import {DocsProvider} from "../../lib/docsProvider";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { setLanguage } from '../../actions';
import API from '../../lib/api';

const styles = (theme: Theme) => createStyles({
    container: {
        flexGrow: 0,
        position: 'relative',
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginLeft: '8px',
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
        background: theme.palette.background.default
    },
});

interface Props extends WithStyles<typeof styles> {
    languages: string[]
}

interface State {
    currentLanguage: string
    isOpen: boolean
}

const displayedLanguageNames = {
    'stagescript': 'StageScript',
    'python': 'StagePython'
}

class LanguageSelector extends React.Component<Props & StateProps & DispatchProps, State> {
    state = {
        currentLanguage: '',
        isOpen: false
    }

    render() {
        const { classes } = this.props;

        const languages = API.getLanguages();
        if (languages.length < 2) {
            return null;
        }

        if (this.props.language !== this.state.currentLanguage) {
            DocsProvider.buildTypeIndex();
            setTimeout(() => {
                this.setState({currentLanguage: this.props.language});
            }, 0);
        }

        const languageNames = Object;
        languages.forEach(language => (languageNames as any)[language] = (displayedLanguageNames as any)[language]);
    
        return (
            <Downshift>
                {({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
                    <div className={classes.container}>
                        <Button 
                            onClick={() => { this.setState({isOpen: !this.state.isOpen})}} 
                            color="inherit"
                            style={{ marginLeft: 8, textTransform: 'none' }}
                        >
                            <CodeIcon style={{paddingRight: 8}} />
                            {(languageNames as any)[this.props.language] || ''}
                        </Button>
                        {this.state.isOpen ? (
                            <Paper className={classes.paper} square>
                                {
                                    languages && languages.filter(language => language !== this.props.language).map(language => 
                                    <Link key={language} to={'/'}>
                                        <Button 
                                            onClick={() => setTimeout(async () => { 
                                                await DocsProvider.setLanguage(language);
                                                this.setState({isOpen: false}, () => this.props.setLanguage(language));
                                            }, 0)
                                            }
                                            color="inherit"
                                            style={{ 
                                                marginLeft: 0, 
                                                marginRight: 0, 
                                                width: '100%',
                                                textTransform: 'none' 
                                            }}
                                        >
                                            <CodeIcon style={{paddingRight: 8}} />
                                            {(languageNames as any)[language] || ''}
                                        </Button>
                                    </Link>)
                                } 
                            </Paper>
                        ) : null}
                    </div>
                )}
            </Downshift>
        )
    }

}

interface StateProps {
    language: string
}

interface DispatchProps {
    setLanguage: (language: string) => void
}

const enhanced = connect<StateProps, DispatchProps>(
    (({ language }: any) => ({ language })),
    dispatch => ({
        setLanguage: (language: string) => setLanguage(dispatch)(language)        
    })
)(LanguageSelector);

const LanguageSelectorWithStyle = withStyles(styles)(enhanced);
export { LanguageSelectorWithStyle as LanguageSelector }
