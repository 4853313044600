import {
    TypeRegistry, ClassInfo, ScoolcodeEnumInfoBase, ValueTypeInfoBase, Type, MethodInfo, ScoolcodeObjectBaseInfo, ScoolcodeMixinManager, ContextualTypeProvider, UnionTypeInfo
} from '@logiscool/scoolcode-language'

function isDerivedFrom(type: Type|null, base: Type) {
    while(type) {
        if(type.baseType && type.baseType.fullName === base.fullName) return true;
        type = type.baseType
    }
    return false
}

export type TypeIndexEntry = { label: string, target: string, kind: string, type: any };

const Mixins = {
    "stagescript": () => {
        const module = require("@logiscool/stagescript-language/dist/src/runtime/StageScriptLanguageMixin");
        return module.StageScriptLanguageMixin;
    },
    "python": () => {
        const module = require("@logiscool/stagepython-language/dist/src/runtime/PythonLanguageMixin");
        return module.PythonLanguageMixin;
    }
}

export class DocsProvider {

    static _language: string;
    static _types: TypeIndexEntry[];

    static async setLanguage(lang: string) {
        if (lang && DocsProvider._language !== lang) {
            let getMixin = (Mixins as any)[lang];
            if (!getMixin) {
                lang = 'stagescript';
                getMixin = (Mixins as any)[lang];
            }
            const mixin = getMixin();
            await mixin.register();
            ScoolcodeMixinManager.setPrimaryLanguage(lang);
            ContextualTypeProvider.getBaseTypes(lang);

            DocsProvider._language = lang;
            localStorage.setItem('@sed::docs/language', lang);
        }
    }

    static get language() {
        return DocsProvider._language;
    }
    
    static getTypeIndex(): TypeIndexEntry[] {
        return DocsProvider._types;
    }

    static buildTypeIndex(): TypeIndexEntry[] {
        DocsProvider._types = this.getTypes()
            .reduce<any[]>((acc, type) => [
                ...acc,
                { label: type.fullName, target: `/${type.fullName}`, kind: 'class', type },
                ...type.properties.map(p => ({ label: p.fullName, target: `/${type.fullName}#${p.name}`, kind: 'property', type: p })),
                ...type.events.map(p => ({ label: p.fullName, target: `/${type.fullName}#${p.name}`, kind: 'event', type: p })),
                ...type.methods.map(p => ({ label: p.fullName, target: `/${type.fullName}#${p.name}`, kind: 'method', type: p }))
            ], [])
            .sort((a: any, b: any) => a.label.localeCompare(b.label));
        return DocsProvider._types;
    }

    static getSuggestions(inputValue: string|null) {
        let count = 0;
    
        return DocsProvider._types.filter(suggestion => {
            if (suggestion.type && suggestion.type instanceof UnionTypeInfo) {
                return false;
            }
            const keep =
                (!inputValue || suggestion.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) &&
                count < 5;
    
            if (keep) {
                count += 1;
            }
    
            return keep;
        });
    }
    
    static extractHierarchy(type: Type) {
        return {
            base: type.baseType,
            type,
            children: this.getTypes().filter(t => isDerivedFrom(t, type))
        }
    }

    static extractKind(type: Type) {
        if(type instanceof ScoolcodeEnumInfoBase) {
            if(type.dynamic) return 'dynamic-enum';
            return 'enum'
        }
        else if(type instanceof ValueTypeInfoBase) {
            return 'value-type'
        }

        return 'class'
    }

    static extractReturnType(method: MethodInfo) {
        if(method.returnType) return method.returnType.fullName;
        else return 'void'
    }

    static extractBaseType(type: Type) {
        if(type.baseType) return type.baseType.fullName;
        else return 'object'
    }

    static getTypes(): ClassInfo[] {
        const types = TypeRegistry.getAll();
        return types
            .filter(type => type instanceof ClassInfo && !type.disabled)
            .sort((a, b) => a.fullName.localeCompare(b.fullName)) as ClassInfo[]
    }

    static getType(name: string): ClassInfo {
        return TypeRegistry.resolve(name) as ClassInfo
    }

    static getPrimaryTypes() {
        return this.getTypes()
            .filter(type => type.baseType instanceof ScoolcodeObjectBaseInfo)
    }

    static getOtherTypes() {
        return this.getTypes()
            .filter(type =>
                !(type instanceof ValueTypeInfoBase) &&
                !(type instanceof ScoolcodeEnumInfoBase) &&
                !(type.baseType instanceof ScoolcodeObjectBaseInfo))
    }

    static getValueTypes() {
        return this.getTypes()
            .filter(type => type instanceof ValueTypeInfoBase)
    }

    static getEnums() {
        return this.getTypes()
            .filter(type => type instanceof ScoolcodeEnumInfoBase)
    }

}
