import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import {OnlineInfoEntry} from "../docsTypeInfo/OnlineInfoContent";
import {connect} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import {InfoCard} from "./InfoCard";
import {resetInfo, setPage, replaceEntry} from "../../actions";
import {AddReleaseNoteButton} from "./AddReleaseNoteButton";
const moment = require('moment');

interface State {
    loading: boolean
}

function date(date: string) {
    return moment(date).fromNow()
}

class ReleaseNotesPage extends React.Component<StateProps & DispatchProps, State> {
    state = {
        loading: false
    }

    componentDidMount() {
        const main = document.querySelector('main');
        if (main) {
            main.addEventListener('scroll', this.onScroll)
        }

        this.props.resetInfo();
        this.props.setPage(1, null)
    }

    componentDidUpdate(prevProps: StateProps) {
        if(this.props.page !== prevProps.page) {
            this.setState({ loading: false })
        }
    }

    onScroll = () => {
        const main = document.querySelector('main');
        if (main) {
            const { loading } = this.state
            const { page, maxPage, setPage, onlineInfo } = this.props
            if (page === maxPage || loading) {
                return
            }

            if ((main.scrollHeight - main.clientHeight - main.scrollTop) < 30) {
                this.setState({ loading: true }, () => {
                    setPage(page + 1, onlineInfo);
                })
            }
        }
    }

    renderEntry = (entry: OnlineInfoEntry) => entry.kind === 'release-note' && (
        <div key={entry.id} style={{ marginBottom: 40 }}>
            <Typography gutterBottom variant='subheading'>
                v{entry.target} released {date(entry.createdAt)}
            </Typography>
            <InfoCard category={entry.category} target={entry.target} expand={true} />
        </div>
    )

    render() {
        const { loading } = this.state;
        const { onlineInfo } = this.props;

        if(!onlineInfo) {
            return null;
        }

        return (
            <div style={{ paddingBottom: 30 }}>
                <Typography variant="display1" gutterBottom>Release Notes</Typography>
                <br/>

                {onlineInfo.map(this.renderEntry)}
                {loading && <div style={{ textAlign: 'center' }}>
                    <CircularProgress size={24} color="primary" />
                </div>}
                <AddReleaseNoteButton />
            </div>
        )
    }
}

interface StateProps {
    page: number,
    maxPage: number,
    onlineInfo: OnlineInfoEntry[]|null
}

interface DispatchProps {
    resetInfo: () => void,
    setPage: (page: number, previousOnlineInfo: any) => void,
    replaceEntry: (entry: OnlineInfoEntry) => void,
}

const enhanced = connect<StateProps, DispatchProps>(
    (({ onlineInfo, page, maxPage }: any) => ({ onlineInfo, page, maxPage })),
    dispatch => ({
        resetInfo: () => resetInfo(dispatch)(),
        setPage: (page: number, previousOnlineInfo: any) => setPage(dispatch)(page, 'release-note,comment', previousOnlineInfo),
        replaceEntry: (entry: OnlineInfoEntry) => replaceEntry(dispatch)(entry)
    })
)(ReleaseNotesPage)

export { enhanced as ReleaseNotesPage }