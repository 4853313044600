import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CommentIcon from '@material-ui/icons/Comment';
import SendIcon from '@material-ui/icons/Send';
import Zoom from '@material-ui/core/Zoom';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MarkdownElement from '../markdownElement';
import Tooltip from '@material-ui/core/Tooltip';
import {ArticleEditorDialog} from "../articleEditorDialog";
import Auth from "../../lib/auth";
import API from "../../lib/api";
import {connect} from "react-redux";
import {Comment} from "./Comment";
import {addEntry, replaceEntry, deleteEntry} from "../../actions";
import Chip from "@material-ui/core/Chip";

export interface OnlineInfoEntry {
    id: string
    category: string,
    target: string,
    author?: string
    status?: number,
    kind: string,
    content: string
    createdAt: string
}

interface Props {
    title: boolean,
    category: string,
    target: string
    minimal?: boolean
    collapsed?: boolean
}

interface State {
    editorOpen: boolean
    commentOpen: boolean
    commentSaving: boolean
    editorSaving: boolean
    hover: boolean
    comment: string
}

class OnlineInfoContent extends React.Component<Props & StateProps & DispatchProps, State> {
    state = {
        editorOpen: false,
        commentOpen: false,
        editorSaving: false,
        commentSaving: false,
        hover: false,
        comment: ''
    }

    componentDidUpdate(prevProps: StateProps) {
        if(this.props.onlineInfo !== prevProps.onlineInfo) {
            if(this.state.editorSaving) {
                this.setState({ editorOpen: false, editorSaving: false })
            }
            if(this.state.commentSaving) {
                this.setState({ commentOpen: false, commentSaving: false, comment: '' })
            }
        }
    }

    renderInfo = (user: any, entry: OnlineInfoEntry) => {
        const { id, kind, content, author, status, category } = entry;
        switch(kind) {
            case 'comment':
                if(!user.grants['edup.scoolcode.docs/editor-mode'] || this.props.minimal) return null;
                return (
                    <Comment key={id} author={author || ''} content={content} status={status || 0} canDelete={user.username === author}
                             onDelete={async () => {
                                await API.deleteEntry(id);
                                this.props.deleteEntry(id)
                             }}
                             onStatusChange={async (status) => {
                                 this.props.replaceEntry(await API.saveEntry({
                                     ...entry,
                                     status
                                 }));
                             }}
                    />
                )

            case 'description':
            case 'release-note':
            default:
            {
                const periodIndex = content.indexOf('.');
                const firstLine = periodIndex > 0 ? content.substring(0, periodIndex + 1) + '..' : content;
                return (
                    <article key={id}>
                        {this.props.title && <>
                            {(kind === 'release-note') && category && <Chip style={{ verticalAlign: 'top' }} label={category}/>}
                            <Typography variant="title"
                                        style={{ display: 'inline-block', padding: 5, verticalAlign: 'top' }}>
                                {(kind === 'release-note') && 'v'}{this.props.target}
                            </Typography>
                            <Divider/>
                        </>}
                        <div style={{padding: 10}}>
                            <MarkdownElement text={this.props.collapsed ? firstLine : content}/>
                        </div>
                    </article>
                )
            }
        }
    }

    handleCommentSave = () => {
        const { category, target } = this.props;
        const user = Auth.getUser();

        this.setState({ commentSaving: true }, async () => {
            if(!this.state.comment) {
                this.setState({ commentOpen: false, commentSaving: false, comment: '' });
                return
            }

            this.props.addEntry(await API.createEntry({
                category,
                target,
                kind: 'comment',
                author: user.username,
                content: this.state.comment
            }))
        })
    }

    handleCommentKeyDown = (event: any) => {
        switch (event.key) {
            case 'Enter':
                if(!this.state.commentSaving) {
                    this.handleCommentSave()
                }
                break

            case 'Escape':
                this.setState({ commentOpen: false, comment: '' })
                break
        }
    }

    render() {
        const { editorOpen, editorSaving, hover, commentOpen, comment, commentSaving } = this.state;
        const { category, target, onlineInfo } = this.props;
        const user = Auth.getUser();
        const isEditor = user.grants['edup.scoolcode.docs/editor-mode'];

        if(onlineInfo) {
            const relevantInfo = onlineInfo
                .filter(e => e.category === category && e.target === target)
                .sort((a,b) => {
                    let aa = new Date(a.createdAt);
                    let bb = new Date(b.createdAt);
                    return aa>bb ? 1 : aa<bb ? -1 : 0
                });

            if(relevantInfo.length) {
                return (
                    <div style={{position: 'relative'}}
                         onMouseEnter={() => this.setState({ hover: true })}
                         onMouseLeave={() => this.setState({ hover: false })}>
                        {this.renderInfo(user, relevantInfo[0])}
                        {commentOpen && <article>
                            <Divider/>
                            <div style={{padding: '5px 5px'}}>
                                &nbsp;
                                <TextField InputProps={{ startAdornment:(
                                                <InputAdornment position="start">
                                                    <CommentIcon />
                                                </InputAdornment>
                                            )}}
                                           style={{ width: 600, verticalAlign: 'bottom' }}
                                           value={comment}
                                           disabled={commentSaving}
                                           onKeyDown={this.handleCommentKeyDown}
                                           autoFocus={true}
                                           onChange={e => this.setState({ comment: e.target.value })}
                                />
                                &nbsp;
                                <Tooltip title="Send">
                                    <Button variant="fab" aria-label="Send" mini disabled={commentSaving} onClick={this.handleCommentSave}>
                                        <SendIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </article>}
                        {(isEditor && !commentOpen) && <Tooltip title="Comment">
                            <Zoom in={hover}>
                                <Button mini
                                        variant="fab"
                                        aria-label="comment"
                                        style={{
                                            position: 'absolute',
                                            right: 65,
                                            bottom: 15
                                        }}
                                        onClick={() => this.setState({ commentOpen: true })}
                                >
                                    <CommentIcon/>
                                </Button>
                            </Zoom>
                        </Tooltip>}
                        {isEditor && <Tooltip title="Edit Description">
                            <Zoom in={hover}>
                                <Button mini
                                        variant="fab"
                                        aria-label="edit"
                                        style={{
                                            position: 'absolute',
                                            right: 15,
                                            bottom: 15
                                        }}
                                        onClick={() => this.setState({ editorOpen: true })}
                                >
                                    <EditIcon/>
                                </Button>
                            </Zoom>
                        </Tooltip>}
                        <ArticleEditorDialog
                            defaultContent={relevantInfo.map(info => info.content).join('\n')}
                            open={editorOpen}
                            saving={editorSaving}
                            onClose={(content?: string | null) => {
                                if(typeof content === 'string') {
                                    this.setState({editorSaving: true},async () => {
                                        this.props.replaceEntry(await API.saveEntry({
                                            ...relevantInfo[0],
                                            content
                                        }))                                        
                                    })
                                }
                                this.setState({ editorOpen: false, editorSaving: false })
                            }}/>
                    </div>
                )
            }
        }

        return (
            <div style={{ padding: 10, position: 'relative' }}
                 onMouseEnter={() => this.setState({ hover: true })}
                 onMouseLeave={() => this.setState({ hover: false })}>
                {isEditor && (
                    <Tooltip title="Add Description">
                        <Button mini
                                variant="fab"
                                color="primary"
                                aria-label="add"
                                style={{
                                    position: 'absolute',
                                    right: 15,
                                    bottom: 15
                                }}
                                onClick={() => this.setState({ editorOpen: true })}
                        >
                            <AddIcon/>
                        </Button>
                    </Tooltip>
                )}
                <ArticleEditorDialog
                    open={editorOpen}
                    saving={editorSaving}
                    onClose={(content?: string | null) => {
                        if(content) {
                            this.setState({editorSaving: true},async () => {
                                this.props.addEntry(await API.createEntry({
                                    category,
                                    target,
                                    kind: 'description',
                                    author: user.username,
                                    content
                                }))
                            })
                        }
                        this.setState({ editorOpen: false, editorSaving: false })
                    }}/>
            </div>
        )
    }
}

interface StateProps {
    onlineInfo: OnlineInfoEntry[]|null,
    page: number,
    storedCategory: string,
    mode: string
}

interface DispatchProps {
    addEntry: (entry: OnlineInfoEntry) => void,
    replaceEntry: (entry: OnlineInfoEntry) => void,
    deleteEntry: (id: string) => void,
}

const enhanced = connect<StateProps, DispatchProps>(
    (({ onlineInfo, page, category, mode }: any) => ({ onlineInfo, page, storedCategory: category, mode })),
    dispatch => ({
        addEntry: (entry: OnlineInfoEntry) => addEntry(dispatch)(entry),
        replaceEntry: (entry: OnlineInfoEntry) => replaceEntry(dispatch)(entry),
        deleteEntry: (id: string) => deleteEntry(dispatch)(id)
    })
)(OnlineInfoContent)
export { enhanced as OnlineInfoContent }
