import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './components';
import './index.css';
import { OauthCallbackPage } from './components/pages/OauthCallbackPage';
import { ProtectedRoute } from './components/protected-route';
import {scMuiTheme} from "./lib/scMuiTheme";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import {LocalLogoutPage} from "./components/pages/LocalLogoutPage";

const IDE_VERSION = '5.38.4';

console.log(`Scoolcode Docs v6.1.16 / runtime v8.13.4`);

const pages = [
    'activity-log',
    'release-notes'
];

class Root extends React.Component<{}, { themeType: string }> {
    state = {
        themeType: window.localStorage.getItem('@theme') || 'light'
    };

    render() {
        const { themeType } = this.state;
        return (
            <Router>
                <MuiThemeProvider theme={scMuiTheme(themeType)}>
                    <Switch>
                        <Route exact path="/401" render={() => 'Unauthorized'}/>
                        <Route exact path="/auth" component={OauthCallbackPage}/>
                        {/*<Route exact path="/logout" component={LogoutPage}/>*/}
                        <Route exact path="/local-logout" component={LocalLogoutPage}/>
                        <ProtectedRoute path="/:name?" render={({ match: { params: { name } } }: any) => {
                            return (
                            <App themeType={themeType}
                                 onThemeChange={(themeType: string) => {
                                     window.localStorage.setItem('@theme', themeType);
                                     this.setState({ themeType })
                                 }}
                                 typeName={pages.indexOf(name) === -1 ? name : null}
                                 page={pages.indexOf(name) === -1 ? null : name}
                            />
                        )}} />
                    </Switch>
                </MuiThemeProvider>
            </Router>
        )
    }
}

const init: any = {
    onlineInfo: null,
    mode: 'normal',
    page: 0,
    maxPage: 0,
    category: '',
    version: IDE_VERSION,
    language: ''
};

const store = createStore((state: any = init, { type, payload }: any) => {
    switch(type) {
        case 'RESET_INFO':
            return {
                ...state,
                onlineInfo: null
            };

        case 'REPLACE_ENTRY':
            return {
                ...state,
                onlineInfo: state.onlineInfo.map((e: any) => e.id === payload.entry.id
                    ? payload.entry
                    : e
                )
            };

        case 'DELETE_ENTRY':
            const onlineInfo = [ ...state.onlineInfo ];
            onlineInfo.splice(onlineInfo.findIndex((e: any) => e.id === payload.id), 1);
            return {
                ...state,
                onlineInfo
            };

        case 'ADD_ENTRY':
            return {
                ...state,
                onlineInfo: [
                    ...state.onlineInfo,
                    payload.entry
                ]
            };

        case 'SET_CATEGORY':
            return {
                ...state,
                category: payload.category,
                onlineInfo: payload.onlineInfo,
                mode: 'normal'
            };

        case 'SET_PAGE':
            return {
                ...state,
                page: payload.page,
                maxPage: payload.maxPage,
                onlineInfo: payload.onlineInfo,
                mode: 'log'
            };

        case 'SET_LANGUAGE':
                return {
                    ...state,
                    language: payload.language,
                    mode: 'log'
                };
    
        default:
            return state
    }
});

ReactDOM.render(
    <Provider store={store}>
        <Root/>
    </Provider>,
    document.getElementById('root') as HTMLElement
);
