import * as React from 'react';
import { ComponentType } from 'react';
import { withTheme } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import HomeIcon from '@material-ui/icons/Home';
import ActivityIcon from '@material-ui/icons/History';
import ReleaseIcon from '@material-ui/icons/NewReleases';
import Divider from '@material-ui/core/Divider';
import {DocsProvider} from "../../lib/docsProvider";
import { Link } from "react-router-dom";
import Auth from "../../lib/auth";
import { connect } from 'react-redux';
import { setLanguage } from '../../actions';
import { UnionTypeInfo } from '@logiscool/scoolcode-language';

interface Props {
    activeName: string
}

interface State {

}

class DocsTypeList extends React.Component<Props & StateProps & DispatchProps & {theme: any}, State> {
    state = {};

    render() {
        const { activeName, theme } = this.props;
        const primaryTypes = DocsProvider.getPrimaryTypes();
        const otherTypes = DocsProvider.getOtherTypes().filter(type => !(type instanceof UnionTypeInfo));
        const valueTypes = DocsProvider.getValueTypes();
        const enums = DocsProvider.getEnums();
        const titleBackgroundColor = theme.palette.background.paper;

        return (
            <>
                <Link to="/">
                    <ListItem button dense>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Home'} style={{ padding: 0 }} />
                    </ListItem>
                </Link>
                <Divider />
                <Link to="/release-notes">
                    <ListItem button dense>
                        <ListItemIcon>
                            <ReleaseIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Release Notes'} style={{ padding: 0 }} />
                    </ListItem>
                </Link>
                <Divider />
                {Auth.getUser().grants['edup.scoolcode.docs/activity-log'] && <>
                    <Link to="/activity-log">
                        <ListItem button dense>
                            <ListItemIcon>
                                <ActivityIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Activity Log'} style={{ padding: 0 }} />
                        </ListItem>
                    </Link>
                    <Divider />
                </>}
                <List
                    dense
                    component="nav"
                    subheader={<ListSubheader component="div" style={{backgroundColor: titleBackgroundColor, marginRight: 10}}>Primary Types</ListSubheader>}
                >
                    {primaryTypes.map(type => (
                        <Link key={type.fullName} to={'/' + type.fullName}>
                            <ListItem button>
                                <ListItemText primary={type.fullName}
                                              primaryTypographyProps={{ color: (activeName === type.fullName) ? 'primary' : 'default'}}/>
                            </ListItem>
                        </Link>
                    ))}
                </List>
                <Divider />
                <List dense
                      component="nav"
                      subheader={<ListSubheader component="div" style={{backgroundColor: titleBackgroundColor, marginRight: 10}}>Primitives</ListSubheader>}
                >
                    {valueTypes.map(type => (
                        <Link key={type.fullName} to={'/' + type.fullName}>
                            <ListItem button>
                                <ListItemText primary={type.fullName}
                                              primaryTypographyProps={{ color: (activeName === type.fullName) ? 'primary' : 'default'}} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
                <Divider />
                <List dense
                    component="nav"
                    subheader={<ListSubheader component="div" style={{backgroundColor: titleBackgroundColor, marginRight: 10}}>Enums</ListSubheader>}
                >
                    {enums.map(type => (
                        <Link key={type.fullName} to={'/' + type.fullName}>
                            <ListItem button>
                                <ListItemText primary={type.fullName}
                                              primaryTypographyProps={{ color: (activeName === type.fullName) ? 'primary' : 'default'}}/>
                            </ListItem>
                        </Link>
                    ))}
                </List>
                <Divider />
                <List dense
                    component="nav"
                    subheader={<ListSubheader component="div" style={{backgroundColor: titleBackgroundColor, marginRight: 10}}>Other</ListSubheader>}
                >
                    {otherTypes.map(type => (
                        <Link key={type.fullName} to={'/' + type.fullName}>
                            <ListItem button>
                                <ListItemText primary={type.fullName}
                                              primaryTypographyProps={{ color: (activeName === type.fullName) ? 'primary' : 'default'}} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </>
        )
    }
}

interface StateProps {
    language: string
}

interface DispatchProps {
    setLanguage: (language: string) => void
}

const enhanced = connect<StateProps, DispatchProps>(
        (({ language }: any) => ({ language })),
        dispatch => ({
            setLanguage: (language: string) => setLanguage(dispatch)(language)        
        })
    )(DocsTypeList);

const EnhancedDocTypeList: ComponentType<any> = withTheme()(enhanced);

export {
    EnhancedDocTypeList as DocsTypeList
}
