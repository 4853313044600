import axios from 'axios'
import Auth from "./auth";
import {OnlineInfoEntry} from "../components/docsTypeInfo/OnlineInfoContent";
import { runtimeEnv } from '../env';

export interface Grant {
    product: string
    feature: string
    version?: string
}

export interface Locale {
    country: string
    language: string
    locale: string
}

export default class API {

    static root = runtimeEnv.REACT_APP_API_URL || 'https://api.scoolcode.com';

    static getLanguages(): string[] {
        const user = Auth.getUser();
        const languages: string[] = [];
        if (user.grants['edup.scoolcode.ide/language-stagescript'] || user.grants['edup.scoolcode.docs/editor-mode']) {
            languages.push('stagescript');
        }
        if (user.grants['edup.scoolcode.ide/language-python'] || user.grants['edup.scoolcode.docs/editor-mode']) {
            languages.push('python');
        }
        return languages;
    }

    static async checkGrants(grants: Grant[]): Promise<Record<string, boolean>> {
        if(!navigator.onLine) return {};
        try {
            return (
                await axios.post<{grants: Grant[]}>(
                `${API.root}/accounts/me/check-grants?access_token=${Auth.getToken().token}`, {grants})
            ).data.grants.reduce<Record<string, boolean>>((prev, curr) => {
                prev[`${curr.product}/${curr.feature}`] = true
                return prev
            }, {});
        }
        catch(e) {
            // document.location.href = '/auth/sc'
            return {}
        }
    }

    static async fetchLocale(): Promise<Locale> {
        const { data }: { data: Locale } = await axios.get(`${API.root}/accounts/me/locale?access_token=${Auth.getToken().token}`);
        return data
    }

    static async fetchOnlineInfo(category: string) {
        if(!navigator.onLine) return null;
        try {
            return (
                await axios.get(
                `${API.root}/documentation-entries?category=${category}&access_token=${Auth.getToken().token}`)
            ).data;
        }
        catch(e) {
            // document.location.href = '/auth/sc'
        }
    }

    static async fetchActivityLog(page: number, filter: string|null) {
        if(!navigator.onLine) return { log: null, maxPage: 1 };
        try {
            const { data: log, headers } = await axios.get(
                filter
                    ? `${API.root}/documentation-entries?sort=-createdAt&limit=10&page=${page}&where[kind][in]=${filter}&access_token=${Auth.getToken().token}`
                    : `${API.root}/documentation-entries?sort=-createdAt&limit=10&page=${page}&access_token=${Auth.getToken().token}`
            );
            const maxPage = parseInt(headers['x-total-count']);

            return {
                log,
                maxPage
            }
        }
        catch(e) {
            // document.location.href = '/auth/sc'
            return { log: null, maxPage: 1 }
        }
    }

    static async fetchLanguageFile(language: string) {
        if(!navigator.onLine) return null;
        try {
            return (
                await axios.get(
                `${API.root}/language-files/scoolcode-ide/${language}.json`)
            ).data;
        }
        catch(e) {
            // document.location.href = '/auth/sc'
        }
    }

    static async createEntry(entry: any) {
        if (!navigator.onLine) return entry;
        try {
            return (
                await axios.post(`${API.root}/documentation-entries?access_token=${Auth.getToken().token}`, entry)
            ).data
        }
        catch (e) {
            // document.location.href = '/auth/sc'
            return entry
        }
    }

    static async deleteEntry(id: string) {
        if (!navigator.onLine) return false;
        try {
            await axios.delete(`${API.root}/documentation-entries/${id}?access_token=${Auth.getToken().token}`)
            return true
        }
        catch (e) {
            // document.location.href = '/auth/sc'
            return false
        }
    }

    static async saveEntry(entry: OnlineInfoEntry) {
        if (!navigator.onLine) return entry;
        try {
            await axios.put(`${API.root}/documentation-entries?access_token=${Auth.getToken().token}`, entry)
            return entry
        }
        catch (e) {
            // document.location.href = '/auth/sc'
            return entry
        }
    }

}
