import * as React from 'react';
import { Type } from '@logiscool/scoolcode-language'
import { Link } from "react-router-dom";

interface Props {
    type: Type|null
    fallback?: string
}

export class TypeLink extends React.Component<Props> {
    render() {
        const { type, fallback } = this.props;
        if(type) return <Link to={`/${type.fullName}`}>{type.fullName}</Link>;
        else return fallback||'void'
    }
}