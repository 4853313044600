import * as React from 'react';
import { ClassInfo } from '@logiscool/scoolcode-language'
import Paper from '@material-ui/core/Paper';
import {OnlineInfoContent, OnlineInfoEntry} from "./OnlineInfoContent";
import Auth from "../../lib/auth";
import {connect} from "react-redux";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';

interface Props {
    type: ClassInfo    
}

class SummaryCard extends React.Component<Props & StateProps & DispatchProps> {
    state = {
        open: false
    }

    onScrollUp = () => {
        const targetClass = document.body.getElementsByClassName("main-conent-div");
        if (targetClass && targetClass.length === 1) {
            const element = targetClass[0];
            if (element) {
                element.scroll(0, 0)
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<Props & StateProps & DispatchProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(this.props.type !== prevProps.type) {
            this.setState({open: false})
        }
    }

    render() {
        const { type, onlineInfo } = this.props;

        if((onlineInfo && onlineInfo.length) || Auth.getUser().grants['edup.scoolcode.docs/editor-mode']) {
            const addOnly = (!onlineInfo || !onlineInfo.length) && Auth.getUser().grants['edup.scoolcode.docs/editor-mode'];

            const renderOnlineContent = (collapsed: boolean) => {
                return (<>
                    {addOnly && <div>
                        <Typography>
                            <i style={{ margin: 15, display: "block" }}>Class description missing.</i>
                        </Typography>
                    </div>}
                    <OnlineInfoContent
                        title={false}
                        category={type.fullName}
                        target={type.fullName}
                        collapsed={collapsed}
                        />
                    <IconButton onClick={() => {
                        this.setState({open: !this.state.open})
                        this.onScrollUp();
                    }}>
                        {this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </>)
            }
            return (
                <>
                    <Paper style={{ marginBottom: 10, padding: 5 }}>
                        {this.state.open ? 
                        <>
                            <IconButton onClick={() => this.setState({open: !this.state.open})}>
                                {this.state.open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Collapse in={this.state.open}>
                                {renderOnlineContent(false)}                                
                            </Collapse>
                        </>
                        : renderOnlineContent(true)}
                    </Paper>
                    <br/>
                </>
            )
        }

        return null
    }
}

interface StateProps {
    onlineInfo: OnlineInfoEntry[]|null
}

interface DispatchProps {
}

const enhanced = connect<StateProps, DispatchProps>(
    (({ onlineInfo }: any) => ({ onlineInfo })),
    { }
)(SummaryCard)
export { enhanced as SummaryCard }