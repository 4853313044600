import * as React from 'react';
import { PropertyInfo } from '@logiscool/scoolcode-language'
import { TypeLink } from "./TypeLink";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {ScoolcodeBlock} from "./ScoolcodeBlock";
import {DocsProvider} from "../../lib/docsProvider";

interface Props {
    type: PropertyInfo
}

export class MinimalPropertyCard extends React.Component<Props> {
    render() {
        const { type: property } = this.props;
        const kind = DocsProvider.extractKind(property.declaringType);
        const hasCode = !property.private;

        return (
            <Paper>
                {hasCode && <Typography style={{ fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace', padding: 5 }}>
                    {property.fullName}:&nbsp;
                    <TypeLink type={property.valueType} />
                    {' { '}
                    {property.getter ? 'get; ' : ''}
                    {property.setter ? 'set; ' : ''}
                    {'}'}
                </Typography>}
                {hasCode && (kind !== 'enum' && kind !== 'dynamic-enum') && <Divider />}
                {(kind !== 'enum' && kind !== 'dynamic-enum') && <>
                    {property.getter && <ScoolcodeBlock type={property} />}
                    {property.setter && <ScoolcodeBlock type={property} modifier="set" />}
                </>}
            </Paper>
        )
    }
}