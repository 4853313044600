import * as React from 'react';
import { EventInfo } from '@logiscool/scoolcode-language'
import { TypeLink } from "./TypeLink";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {OnlineInfoContent} from "./OnlineInfoContent";
import {ScoolcodeBlock} from "./ScoolcodeBlock";

interface Props {
    type: EventInfo
}

export class EventCard extends React.Component<Props> {
    render() {
        const { type: event } = this.props;
        const hasCode = !event.private;

        return (
            <Paper style={{ marginBottom: 20 }}>
                <Typography variant="title" component={(props: any) => 
                    <h3 {...props} id={event.name}>
                        {event.name}
                    </h3>} style={{ padding: 10 }}>
                </Typography>
                <Divider />
                {hasCode && <>
                    <Typography style={{ fontFamily: 'Menlo, Monaco, Consolas, "Courier New", monospace', padding: 10 }}>
                        <strong>when</strong> {event.name}(
                        {event.parameters.map((param, i) => (
                            <span key={i}>
                                {param.name}: <TypeLink type={param.type} />
                                {i+1 < event.parameters.length ? ', ' : ''}
                            </span>
                        ))})
                    </Typography>
                    <Divider />
                </>}
                <ScoolcodeBlock type={event} />
                <Divider />
                <OnlineInfoContent
                    title={false}
                    category={event.declaringType.fullName}
                    target={event.fullName} />
            </Paper>
        )
    }
}