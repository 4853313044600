import * as React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import {ArticleEditorDialog} from "../articleEditorDialog";
import Auth from "../../lib/auth";
import API from "../../lib/api";
import {connect} from "react-redux";
import {addEntry} from "../../actions";
import {OnlineInfoEntry} from "../docsTypeInfo/OnlineInfoContent";

interface Props {

}

interface State {
    editorOpen: boolean
    editorSaving: boolean
}

class AddReleaseNoteButton extends React.Component<Props & StateProps & DispatchProps, State> {
    state = {
        editorOpen: false,
        editorSaving: false
    }

    componentDidUpdate(prevProps: StateProps) {
        if(this.props.onlineInfo !== prevProps.onlineInfo) {
            if(this.state.editorSaving) {
                this.setState({ editorOpen: false, editorSaving: false })
            }
        }
    }

    render() {
        const { editorOpen, editorSaving } = this.state;
        const { version, onlineInfo } = this.props;
        const user = Auth.getUser();
        const isEditor = user.grants['edup.scoolcode.docs/editor-mode'] && onlineInfo && !onlineInfo.find(
            entry => entry.kind === 'release-note' && entry.target === version
        );

        return (
            <>
                {isEditor && (
                    <Tooltip title="Add Release Note">
                        <Button variant="fab"
                                color="primary"
                                aria-label="add"
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: 70
                                }}
                                onClick={() => this.setState({ editorOpen: true })}
                        >
                            <AddIcon/>
                        </Button>
                    </Tooltip>
                )}
                <ArticleEditorDialog
                    open={editorOpen}
                    saving={editorSaving}
                    onClose={(content?: string | null) => {
                        if(content) {
                            this.setState({editorSaving: true},async () => {
                                this.props.addEntry(await API.createEntry({
                                    category: 'beta',
                                    target: version,
                                    kind: 'release-note',
                                    author: user.username,
                                    content
                                }))
                            })
                        }
                        this.setState({ editorOpen: false, editorSaving: false })
                    }}/>
            </>
        )
    }
}

interface StateProps {
    onlineInfo: OnlineInfoEntry[]|null,
    version: string
}

interface DispatchProps {
    addEntry: (entry: OnlineInfoEntry) => void
}

const enhanced = connect<StateProps, DispatchProps>(
    (({ onlineInfo, version }: any) => ({ onlineInfo, version })),
    dispatch => ({
        addEntry: (entry: OnlineInfoEntry) => addEntry(dispatch)(entry)
    })
)(AddReleaseNoteButton)
export { enhanced as AddReleaseNoteButton }
