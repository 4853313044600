import { LocalisationService } from '@logiscool/scoolcode-language';
import { ComponentType } from 'react';
import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Chip from '@material-ui/core/Chip';
import { Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LightBulbIcon from '@material-ui/icons/BrightnessMedium';
import ExitIcon from '@material-ui/icons/ExitToApp';
import LinearProgress from '@material-ui/core/LinearProgress';
import API from '../../lib/api';
import Auth from '../../lib/auth';
import { DocsTypeList } from '../docsTypeList';
import { DocsTypeInfo } from '../docsTypeInfo';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import {OnlineInfoEntry} from "../docsTypeInfo/OnlineInfoContent";
import {SearchBox} from "../searchBox";
import { LanguageSelector } from '../languageSelector';
import {WelcomeCard} from "./WelcomeCard";
import {PageView} from "../pages";
import { connect } from "react-redux"
import {Route} from "react-router";
import {resetInfo, setCategory, setLanguage} from "../../actions";
import { DocsProvider } from '../../lib/docsProvider';

const drawerWidth = 240;
const styles = (theme: Theme) => createStyles({
    appFrame: {
        zIndex: 1,
        flexGrow: 1,
        overflow: 'hidden',
        position: 'fixed',
        display: 'flex',
        width: '100%'
    },
    appBar: {
        position: 'fixed',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'appBarShift-left': {
        marginLeft: drawerWidth,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        position: 'fixed',
        overflow: 'auto',
        height: "100%",
        right: 0,
        left: 0,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    'content-left': {

    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'contentShift-left': {
        marginLeft: drawerWidth,
    },
});

export interface LanguageSetup {
    currentLanguage: string;
    languages: string[];
}

interface Props extends WithStyles<typeof styles> {
    themeType: string
    typeName: string|null
    page: string|null
    onThemeChange: (themeType: string) => void    
}

interface State {
    open: boolean,
    loading: boolean,
    languageSetup: LanguageSetup,
    typeName: string|null
}

class App extends React.Component<Props & StateProps & DispatchProps, State> {
    state = {
        open: false,
        loading: false,
        languageSetup: {
            currentLanguage: '',
            languages: [] as string[]
        },
        typeName: null
    };

    handleRouteChange(typeName?: string | null) {
        if(this.props.page) return;
        typeName = typeName || this.props.typeName;
        if(typeName) {
            const suggestions = DocsProvider.getSuggestions(typeName);
            if (suggestions && suggestions.length > 0) {
                const index = suggestions.findIndex(s => s.label === typeName);
                typeName = suggestions[index === -1 ? 0 : index].label.replace('.', '#');
                this.setState({typeName: typeName, loading: this.state.typeName !== typeName}, async () => {
                    this.props.setCategory(typeName as string)
                })
                return;
            }
        }

        this.props.resetInfo();
        this.setState({ open: true, loading: false }, () => this.handleHash());        
    }

    handleHash() {
        const main = document.querySelector('main') as any;
        if(window.location.hash) {
            console.log("HASH: " + window.location.hash);
            const element = document.getElementById(window.location.hash.replace('#', ''));
            if(element) {
                main.scrollTop = element.offsetTop - 75;
            }
        }
        else {
            main.scrollTop = 0
        }
    }

    async componentDidMount() {
        console.log(`App started!`);
        const handleRouteChange = () => {
            if (window.location.pathname.length > 1) {
                // A pathname other than /
                this.props.setCategory(window.location.pathname.slice(1));
            }
            if (this.props.typeName) {
                if (this.state.typeName !== this.props.typeName) {
                    this.handleHash();
                }
                else {
                    this.setState({typeName: this.props.typeName});
                }
            }
            else {
                this.handleRouteChange();
            }
        }
        if(navigator.onLine) {
            LocalisationService.Default.loadFile(await API.fetchLanguageFile(Auth.getUser().language));
            
            const languages = API.getLanguages();
            this.setState({languageSetup: {
                currentLanguage: this.state.languageSetup.currentLanguage,
                languages
            }});

            const setLanguage = (lang: string) => {
                DocsProvider.setLanguage(lang).then(() => {
                    this.props.setLanguage(lang);
                    handleRouteChange()
                });
            }

            const urlLanguage = new URLSearchParams(window.location.search).get('language');
            if (urlLanguage && languages && languages.includes(urlLanguage)) {
                // The url language is among the languages this user may access.
                return setLanguage(urlLanguage);
            }
            else {
                const storedLang = localStorage.getItem('@sed::docs/language');
                if (storedLang) {
                    return setLanguage(storedLang);
                }
                else if (languages.length > 0) {
                    if (languages.indexOf('stagescript') > -1) {
                        return setLanguage('stagescript');
                    }
                    return setLanguage(languages[0]);
                }
                else {
                    // Fallback to the good old stagescript
                    return setLanguage('stagescript');
                }
            }
        }
        window.onhashchange = this.handleHash;
        handleRouteChange();
    }

    componentDidUpdate(prevProps: Props & StateProps) {
        if(this.props.typeName !== prevProps.typeName) {
            this.handleRouteChange(this.props.typeName || prevProps.typeName);
        }
        else if(this.props.onlineInfo !== prevProps.onlineInfo && this.state.loading) {
            this.setState({ loading: false }, () => {
                if (this.state.typeName && this.state.typeName !== this.props.typeName && this.props.language) {
                    this.handleRouteChange(this.state.typeName);
                }
                else {
                    this.handleHash();                
                }
            })
        }
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes, themeType, onThemeChange, typeName, page, version } = this.props;
        const { open, loading } = this.state;

        const drawer = (
            <Drawer
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <Typography variant="title" noWrap style={{ flex: 1, marginLeft: 15 }}>
                        Legend
                    </Typography>
                    <IconButton onClick={this.handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <section style={{height: 'calc(100vh - 64px)', overflowY: 'scroll'}}>
                    <DocsTypeList activeName={typeName||page||''} />
                </section>
            </Drawer>
        );

        return (
            <div className={classes.appFrame}>
                <AppBar position="absolute" className={classNames(classes.appBar, {
                    [classes.appBarShift]: open,
                    [classes[`appBarShift-left`]]: open,
                })}>
                    <Toolbar disableGutters={!open}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="title" color="inherit" noWrap style={{ flex: 1 }}>
                            Scoolcode Docs
                        </Typography>
                        <SearchBox/>
                        <Tooltip id="theme-icon" title="Toggle light/dark theme">
                            <IconButton
                                color="inherit"
                                aria-label="Toggle light/dark theme"
                                onClick={() => onThemeChange(themeType === 'dark' ? 'light' : 'dark')}
                                style={{ marginLeft: 8 }}
                            >
                                <LightBulbIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip id="theme-icon" title="Logout">
                            <IconButton
                                color="inherit"
                                aria-label="Logout"
                                onClick={() => {
                                    this.setState({
                                        open: false,
                                        loading: false,
                                        languageSetup: {
                                            currentLanguage: '',
                                            languages: []
                                        },
                                        typeName: null
                                    }, Auth.logout);
                                }}
                                style={{ marginLeft: 8 }}
                            >
                                <ExitIcon />
                            </IconButton>
                        </Tooltip>
{/*
                        <Button color="inherit">Login</Button>
*/}
                        <LanguageSelector/>  
                        <Chip label={`v${version}`} style={{ marginRight: open ? 0 : 24, marginLeft: 8 }} />
                    </Toolbar>
                    {loading && <LinearProgress />}
                </AppBar>
                {drawer}
                <main className={classNames("main-conent-div", classes.content, classes[`content-left`], {
                    [classes.contentShift]: open,
                    [classes[`contentShift-left`]]: open,
                })}>
                    <div className={classes.drawerHeader} />
                    <Route exact path='/' component={WelcomeCard} />
                    {page && <PageView page={page} />}
                    {typeName && <DocsTypeInfo typeName={typeName} />}
                </main>
            </div>
        )
    }
}

interface StateProps {
    onlineInfo: OnlineInfoEntry[]|null
    version: string
    language: string
}

interface DispatchProps {
    resetInfo: () => void,
    setCategory: (category: string) => void,
    setLanguage: (language: string) => void
}

const enhanced = connect<StateProps, DispatchProps>(
    (({ onlineInfo, version, language }: any) => ({ onlineInfo, version, language })),
    dispatch => ({
        resetInfo: () => resetInfo(dispatch)(),
        setCategory: (category: string) => setCategory(dispatch)(category),
        setLanguage: (language: string) => setLanguage(dispatch)(language)
    })
)(App);
const AppWithStyles: ComponentType<any> = withStyles(styles)(enhanced);

export { AppWithStyles as App }
