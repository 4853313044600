import { runtimeEnv } from "../env";

export default class Auth {

    static root = runtimeEnv.REACT_APP_API_URL;
    private static user: any = null
    
    static handlePath = () => {
        if (!['/', '/auth'].includes(window.location.pathname)) {
            const newPath = window.location.pathname + window.location.search + window.location.hash;
            localStorage.removeItem('@sed::docs/path');
            localStorage.setItem('@sed::docs/path', newPath);
        }
    }

    static saveToken(token: string): void {
        const to = {
            token,
            expiresOn: !token
                ? 0
                : Date.now() + 10 * 60 * 60 * 1000
        };

        localStorage.removeItem('@sed::docs/token');
        localStorage.setItem('@sed::docs/token', JSON.stringify(to));        
    }

    static saveUser(user: any) {
        this.user = user
    }

    static getUser() {
        if (!this.user || !window.navigator.onLine) {
            return {grants: {}, language: 'en'}
        }
        return this.user
    }

    static getToken(): {token?: string; expiresOn?: number} {
        try {
            return JSON.parse(localStorage.getItem('@sed::docs/token') || '{}')
        } catch (e: any) {
            console.warn(e.message);
            return {};
        }
    }

    static fetchPath(): string {
        const path = localStorage.getItem('@sed::docs/path');
        localStorage.removeItem('@sed::docs/path');
        return path || '/';
    }

    static logout() {
        document.cookie = document.cookie
            .split(';')
            .map(v => v.split('='))
            .filter(([k]) => k.trim() !== 'access_token')
            .map(([k, v]) => `${k}=${v}`)
            .join(';');
        localStorage.removeItem('@sed::docs/language');
        localStorage.removeItem('@sed::docs/token');
        localStorage.removeItem('user');
        const assignUrl = `${runtimeEnv.REACT_APP_OAUTH_URL}/api/logout?redirect=${window.location.origin}/login?from=${'/'}`;
        window.location.assign(assignUrl);
    }

    static logoutLocal() {
        document.cookie = document.cookie
            .split(';')
            .map(v => v.split('='))
            .filter(([k]) => k.trim() !== 'access_token')
            .map(([k, v]) => `${k}=${v}`)
            .join(';');
        localStorage.removeItem('@sed::docs/language');
        localStorage.removeItem('@sed::docs/token');
        localStorage.removeItem('user');
        window.location.assign(window.location.origin+"/login?from=/");
    }

}

Auth.handlePath();