import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import {OnlineInfoEntry} from "../docsTypeInfo/OnlineInfoContent";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Comment} from "../docsTypeInfo/Comment";
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import {InfoCard} from "./InfoCard";
import {resetInfo, setPage, replaceEntry} from "../../actions";
import API from "../../lib/api";
const moment = require('moment');

interface State {
    loading: boolean
}

function date(date: string) {
    return moment(date).fromNow()
}

class ActivityLogPage extends React.Component<StateProps & DispatchProps, State> {
    state = {
        loading: false
    }

    componentDidMount() {
        const main = document.querySelector('main');
        if (main) {
            main.addEventListener('scroll', this.onScroll)
        }

        this.props.resetInfo();
        this.props.setPage(1, null)
    }

    componentDidUpdate(prevProps: StateProps) {
        if(this.props.page !== prevProps.page) {
            this.setState({ loading: false })
        }
    }

    onScroll = () => {
        const main = document.querySelector('main');
        if (main) {
            const { loading } = this.state
            const { page, maxPage, setPage, onlineInfo } = this.props
            if (page === maxPage || loading) {
                return
            }

            if ((main.scrollHeight - main.clientHeight - main.scrollTop) < 30) {
                this.setState({ loading: true }, () => {
                    setPage(page + 1, onlineInfo);
                })
            }
        }
    }

    renderEntry = (entry: OnlineInfoEntry) => {
        let memberName = (entry.category === entry.target)
            ? ''
            : '#' + entry.target.replace(entry.category + '.', '');
        let link = <Link to={`/${entry.category}#${memberName}`}>
            <strong>{entry.target}</strong>
        </Link>

        let text: any = 'Something happened to';
        let Card: any = null;
        switch (entry.kind) {
            case 'comment':
                text = <>{date(entry.createdAt)} <strong>{entry.author || 'someone'}</strong> commented
                    on {link}</>
                Card = <Paper style={{marginTop: 10, padding: 5}}>
                    <Typography variant="title"
                                style={{padding: 5}}>
                        {entry.target}
                    </Typography>
                    <Comment author={entry.author || ''} content={entry.content} status={entry.status || 0}
                             onStatusChange={async (status) => {
                                 this.props.replaceEntry(await API.saveEntry({
                                     ...entry,
                                     status
                                 }));
                             }}/>
                </Paper>
                break;

            case 'description':
                text = <>{date(entry.createdAt)} <strong>{entry.author || 'someone'}</strong> created description
                    for {link}</>
                Card = <InfoCard category={entry.category} target={entry.target}/>
                break;

            default:
                return null
        }


        return (
            <div key={entry.id} style={{ marginBottom: 40 }}>
                <Typography gutterBottom variant='subheading'>{text}</Typography>
                {Card}
            </div>
        )
    }

    render() {
        const { loading } = this.state;
        const { onlineInfo } = this.props;

        if(!onlineInfo) {
            return null;
        }

        return (
            <div style={{ paddingBottom: 30 }}>
                <Typography variant="display1" gutterBottom>Activity Log</Typography>
                <br/>

                {onlineInfo.map(this.renderEntry)}
                {loading && <div style={{ textAlign: 'center' }}>
                    <CircularProgress size={24} color="primary" />
                </div>}
            </div>
        )
    }
}

interface StateProps {
    page: number,
    maxPage: number,
    onlineInfo: OnlineInfoEntry[]|null
}

interface DispatchProps {
    resetInfo: () => void,
    setPage: (page: number, previousOnlineInfo: any) => void,
    replaceEntry: (entry: OnlineInfoEntry) => void,
}

const enhanced = connect<StateProps, DispatchProps>(
    (({ onlineInfo, page, maxPage }: any) => ({ onlineInfo, page, maxPage })),
    dispatch => ({
        resetInfo: () => resetInfo(dispatch)(),
        setPage: (page: number, previousOnlineInfo: any) => setPage(dispatch)(page, null, previousOnlineInfo),
        replaceEntry: (entry: OnlineInfoEntry) => replaceEntry(dispatch)(entry)
    })
)(ActivityLogPage)

export { enhanced as ActivityLogPage }