import * as React from 'react';
import {ActivityLogPage} from "./ActivityLogPage";
import {ReleaseNotesPage} from "./ReleaseNotesPage";
import {WelcomeCard} from "../app/WelcomeCard";

interface Props {
    page: string
}

export class PageView extends React.Component<Props> {
    render() {
        const { page } = this.props;

        switch(page) {
            case 'activity-log':
                return <ActivityLogPage />

            case 'release-notes':
                return <ReleaseNotesPage />

            default:
                return <WelcomeCard />
        }
    }
}